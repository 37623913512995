'use client';
import React, { useEffect, useRef } from 'react';
import Lenis from '@studio-freight/lenis';
import { usePathname } from 'next/navigation';

const SmoothScroller = () => {
    const lenisRef = useRef<Lenis | null>(null);
    const pathname = usePathname();

    useEffect(() => {

        const lenis = new Lenis();
        lenisRef.current = lenis;
        const htmlElement = document.documentElement;

        function raf(time: any) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        function checkLenisState() {
            if (htmlElement.classList.contains('lenis-stopped')) {
                lenis.stop();
            } else {
                lenis.start();
                requestAnimationFrame(raf);
            }
        }

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    checkLenisState();
                }
            });
        });

        observer.observe(htmlElement, { attributes: true });

        requestAnimationFrame(raf);

        return () => {
            observer.disconnect();
            lenis.destroy();
        };

    }, []);

    useEffect(() => {
        if (lenisRef .current) {
            lenisRef.current?.scrollTo(0, { immediate: true });
        }
      }, [pathname]);

    return <></>;
}

export default SmoothScroller;