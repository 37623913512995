'use client'

import React, {useState, useEffect} from 'react';
import { Vector3  } from 'three';
import { Canvas } from '@react-three/fiber'
import classes from './canvas.module.scss'
import {AnimatedNoise} from './animated-noise';
import Image from 'next/image';

export default function BaseCanvas() {

    const [width, setWidth] = useState(0);

    useEffect(() => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
    }, [])

    return (
        <>
        <div className={classes['wrapper']}>
            {width > 1024 ?
            <Canvas shadows
                style={{ width: '100vw', height: '100vh', zIndex: -1 }}
                className={classes['canvas']}
                camera={{ position: new Vector3(0,0,2), fov: 1 }}>
                    <AnimatedNoise />
            </Canvas>
            : <Image className={classes['cover']} src="/images/fallback.webp" alt="fallback image" width={911} height={1897} />}
        </div>
        </>
    )
}