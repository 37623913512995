import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/PolySans-Neutral.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/PolySans-Median.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/PolySans-Bulky.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-display\"}],\"variableName\":\"polysans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/InstrumentSerif-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/InstrumentSerif-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"}],\"variable\":\"--font-italic\"}],\"variableName\":\"instrument\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/utils/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Inter-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Inter-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Inter-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Inter-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-body\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/canvas/background-canvas.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/smooth-scroller/smooth-scroller.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/app.scss");
