'use client';

import React from 'react';
import classes from './header.module.scss';
import Logo from '@/assets/images/logo.svg';
import Link from 'next/link';
import { usePathname } from 'next/navigation'

export const Header = () => {
  const nextPath = usePathname();

  const isActive = (pathname: String) => {
    return nextPath === pathname;
  };

  return (
    <div className={classes['header-wrapper']}>
        <div className={classes['content-area']}>
            <Link href="/">
              <Logo aria-label="Click to return home" />
            </Link>
            <div className={classes['nav']}>
              <Link href="/projects" className={isActive('/projects') ? classes['active'] : ''}>Projects</Link>
              <a href="/contact" className={isActive('/contact') ? classes['active'] : ''}>Contact</a>
            </div>
        </div>
    </div>
  );
};
