// NoiseMaterial.tsx
import { extend, ReactThreeFiber } from '@react-three/fiber';
import { ShaderMaterial, Vector2 } from 'three';

export class NoiseMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: {
                u_time: { value: 0 },
                u_resolution: { value: new Vector2(window.innerWidth, window.innerHeight) },
                u_mouse: { value: new Vector2(0, 0) }, // Mouse position, initialized outside the screen
                u_device_pixel_ratio: { value: window.devicePixelRatio || 1 }
            },
            vertexShader:
                `void main() {
            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * modelViewPosition;
        }`,
            fragmentShader: `
            uniform vec2 u_resolution;
            uniform vec2 u_mouse;
            uniform float u_time;
            uniform float u_device_pixel_ratio;

            vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
            vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
            vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

            float snoise(vec2 v) {
                const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                                    0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                                    -0.577350269189626,  // -1.0 + 2.0 * C.x
                                    0.024390243902439); // 1.0 / 41.0
                vec2 i  = floor(v + dot(v, C.yy) );
                vec2 x0 = v -   i + dot(i, C.xx);
                vec2 i1;
                i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
                vec4 x12 = x0.xyxy + C.xxzz;
                x12.xy -= i1;
                i = mod289(i); // Avoid truncation effects in permutation
                vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
                    + i.x + vec3(0.0, i1.x, 1.0 ));
            
                vec3 m = max(0.48 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
                m = m*m ;
                m = m*m ;
                vec3 x = 2.0 * fract(p * C.www) - 1.0;
                vec3 h = abs(x) - 0.5;
                vec3 ox = floor(x + 0.5);
                vec3 a0 = x - ox;
                m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
                vec3 g;
                g.x  = a0.x  * x0.x  + h.x  * x0.y;
                g.yz = a0.yz * x12.xz + h.yz * x12.yw;
                return 130.0 * dot(m, g);
            }
            
            void main() {
                vec2 st = gl_FragCoord.xy / u_resolution.xy * u_device_pixel_ratio;
                float aspectRatio = u_resolution.x / u_resolution.y;
                st.x *= aspectRatio;
                vec3 color = vec3(0.0);

                // Convert mouse position to [0,1] range and adjust for aspect ratio
                vec2 mousePos = u_mouse;
                mousePos.y = mousePos.y; // Invert Y if necessary
                mousePos.x = mousePos.x;

                float linearTime = u_time * 0.02;

                // Calculate distance from current fragment to mouse position
                float mouseDistance = distance(st, mousePos);
                
                // Define the radius within which the effect is applied
                float effectRadius = 0.0; // The maximum radius of effect
                float falloff = smoothstep(effectRadius, 0.1, mouseDistance);
                float innerRadius = 0.0; // Starting point of the effect, usually 0 for the mouse position

                // Adjust noise scale based on distance, with a smooth falloff from the mouse position
                float noiseScale = mix(0.6, 0.5, smoothstep(innerRadius, effectRadius, mouseDistance));

                vec2 pos = st * vec2(20.0, 20.0);

                float timeFalloff = exp(-u_time * 0.5); // Adjust the multiplier for faster/slower falloff
                float noiseIntensity = snoise(st * 50.0) * falloff * timeFalloff;

                // Smooth, looped oscillation using sine
                float oscillatingTime = sin(u_time * 0.1) * cos(u_time * 0.1) * 2.15 + 2.15; // Normalized to [0,1]

                vec2 vel = vec2(oscillatingTime);
                float DF = 0.0;

                DF += snoise(pos + vel) * 0.25 + 0.25;
                vec2 direction = normalize(vec2(1.0, 0.5)); // Example constant direction

                // Adjust for less skewed, more even distribution
                float a = snoise(pos * 0.4) * 3.1415;
                vel = vec2(cos(a), sin(a));
                DF += snoise(pos + linearTime + direction ) * 0.5;

                color = vec3(smoothstep(0.6, 0.8, fract(DF)));

                vec3 bgColor = vec3(15.0/255.0, 15.0/255.0, 15.0/255.0);
                vec3 dotColor = vec3(23.0/255.0, 23.0/255.0, 23.0/255.0);
                vec3 finalColor = mix(bgColor, dotColor, step(0.1, color.x));

                gl_FragColor = vec4(finalColor, 1.0);
            }
        `,
        });
    }
}

extend({ NoiseMaterial });


declare global {
    namespace JSX {
        interface IntrinsicElements {
            noiseMaterial: ReactThreeFiber.Object3DNode<ShaderMaterial, typeof ShaderMaterial>;
        }
    }
}

