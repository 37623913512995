'use client';
import React, { useRef } from 'react';
import { useFrame, extend, useThree } from '@react-three/fiber';
import { ShaderMaterial, Mesh } from 'three';
import { NoiseMaterial } from './noise-material';


extend({ NoiseMaterial });

// This component will be rendered inside the Canvas component
export const AnimatedNoise = () => {
  const materialRef = useRef<Mesh>(null);
  const {clock} = useThree();

  useFrame(() => {
    if (materialRef.current) {
      const elapsedTime = clock.getElapsedTime();
      (materialRef.current.material as NoiseMaterial).uniforms.u_time.value = elapsedTime * 0.1; // Slow down time effect
    }
  });

  return (
    <mesh ref={materialRef}>
      <planeGeometry args={[2,2]} />
      <noiseMaterial  />
    </mesh>
  );
};
